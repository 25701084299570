<template>
  <GenericBanner header="About Us" image_name="about-banner.jpg" />

  <section class="about-text">
    <div class="flex-grid">
      <div class="element text" id="visText">
        <h2>Vision</h2>
        <p>
          Totem Game Dev is the first student-led community focused on Game
          Development tying together all universities in the Netherlands. Our goal
          is to bring together students passionate about making games and give them
          the tools to realize their vision and learn about the industry.
        </p>
        <br />
        <p>
          Our mission is to create a space where students can explore their
          passion and connect with like-minded people. By doing so, we hope to
          encourage them to experiment and to discover the vast world of game
          development!
        </p>
      </div>
      <div class="element image" id="visImg">
        <img src="@/assets/about/undraw-video-game-night.svg" alt="" />
      </div>
      <div class="element image" id="AchImg">
        <img src="@/assets/about/undraw-researching.svg" alt="" />
      </div>
      <div class="element text" id="AchText">
        <h2>How do we achieve this?</h2>
        <p>
          We use a holistic learning approach and focus on all aspects of
          video-game making, from programming to design and art. We organize
          different learning experiences crafted with the students' needs in
          mind. Some examples of activity types include guided workshops,
          working sessions and joining Game Jam competitions. Whenever members
          feel ready to tackle a challenge, they can sign up for Game Dev Teams,
          a long-term project where teams use the skills they learned to put
          together their own game! Besides this, we also organize informal
          activities where members can get to know each other and hang out. In
          the future, we hope to expand our activity repertoire and offer
          members the opportunity to join office visits and lectures from
          industry veterans.
        </p>
      </div>
    </div>
  </section>

  <section class="external-sources middle">
    <h1>What others say about us</h1>
    <div class="external-sources-row row centered">
      <div v-for="src in externalSources" :key="src.title" class="col">
        <img :src="`/images/external-sources/${src.img}`" />
        <div>
          <div class="source-tag">
            <i class="fas fa-newspaper" v-if="src.type === 'article'"></i>
            <i class="fas fa-video" v-if="src.type === 'video'"></i>
            <span>{{ src.source }}</span>
          </div>
          <h3><a :href="src.link" target="_blank" class="hover-link-style">{{ src.title }}</a></h3>
        </div>
      </div>
    </div>
  </section>

  <section class="partners middle">
    <h1>Partners</h1>
    <div class="partners-row row centered">
      <a v-for="partner in partners" :key="partner.name" class="col" :href="partner.link" target="_blank">
        <img v-if="partner.type === 'img'" :src="`/images/partners/${partner.logo}`" :alt="partner.name" :title="partner.name">
        <div v-if="partner.type === 'svg'">
          {{ partner.logo }}
        </div>
      </a>
    </div>
  </section>
</template>

<script>
import GenericBanner from "@/components/GenericBanner.vue";

export default {
  name: "About",
  components: {
    GenericBanner,
  },
  data() {
    return {
      founders: [
        {
          name: "Efstratios Eftychiadis",
          img: "Stratos.jpg",
          linkedin: "https://www.linkedin.com/in/efstratios-eftychiadis-aa128920a/",
        },
        {
          name: "Ongun Korkmaz",
          img: "Ongun.jpeg",
          linkedin: "https://www.linkedin.com/in/ongun-k-710022140",
          website: "https://ongunkorkmaz.wixsite.com/website",
          instagram: "https://www.instagram.com/ongun_korkmaz/",
        },
        {
          name: "Jesse Traas",
          img: "Jesse.png",
          github: "https://github.com/PXJesse",
          linkedin: "https://www.linkedin.com/in/jesse-traas-0829ba1b9/",
          website: "https://jessetraas.nl/",
        },
        {
          name: "Sanziana Tudose",
          img: "Ziana.jpg",
          github: "https://github.com/SanzianaTudose",
          linkedin: "https://www.linkedin.com/in/sanziana-tudose/",
        },
        {
          name: "Athanasios Tsalafoutas",
          img: "Thanos.jpg",
          linkedin: "https://www.linkedin.com/in/athanasios-tsalafoutas-061491227/",
        },
      ],
      board: [
        {
          name: "Ongun Korkmaz",
          img: "ongun.jpeg",
          linkedin: "https://www.linkedin.com/in/ongun-k-710022140",
          website: "https://ongunkorkmaz.wixsite.com/website",
          instagram: "https://www.instagram.com/ongun_korkmaz/",
        },
        {
          name: "Egor",
          img: "egor.jpg",
        },
        {
          name: "Sanziana Tudose",
          img: "ziana.jpg",
          github: "https://github.com/SanzianaTudose",
          linkedin: "https://www.linkedin.com/in/sanziana-tudose/",
        },
        {
          name: "Jesse Traas",
          img: "jesse.png",
          github: "https://github.com/PXJesse",
          linkedin: "https://www.linkedin.com/in/jesse-traas-0829ba1b9/",
          website: "https://jessetraas.nl/",
        },
        {
          name: "Teoman",
          img: "teoman.jpg",
        },
        {
          name: "Thomas",
          img: "thomas.jpg",
        },
        {
          name: "Willem",
          img: "anonymous.jpg",
        },
        {
          name: "Paul",
          img: "anonymous.jpg",
        },
        {
          name: "Kenna",
          img: "kenna.jpg",
        },
        {
          name: "Maarten",
          img: "anonymous.jpg",
        },
      ],
      externalSources: [
        {
          title: "TU/e Game Dev prepares students for gaming industry",
          type: 'article',
          source: 'Cursor',
          link: 'https://www.cursor.tue.nl/en/news/2022/januari/week-4/tu/e-game-dev-prepares-students-for-gaming-industry/',
          img: 'cursor-tue-game-dev-prepares.jpg'
        },
        {
          title: "Studenten maken zelf games",
          type: 'video',
          source: 'Studio040',
          link: 'https://www.youtube.com/watch?v=Jn_gfaTnx7U',
          img: 'studio040-studenten-maken.jpg'
        },
      ],
      partners: [
        {
          name: "Google Developer Student Club Eindhoven",
          type: "img",
          logo: "gdsc-logo.svg",
          link: "https://gdsc.community.dev/eindhoven-university-of-technology/",
        },
        {
          name: "innovation Space",
          type: "img",
          logo: "innospace-logo.png",
          link: "https://www.tue.nl/en/education/tue-innovation-space/",
        },
        {
          name: "Studenten Drukwerk",
          type: "img",
          logo: "studenten-drukwerk-logo.png",
          link: "https://studentendrukwerk.nl/",
        },
      ]
    };
  },
  computed: {
    foundersWithBreak() {
      let foundersInserted = this.founders.slice();
      foundersInserted.splice(3, 0, {});
      return foundersInserted;
    },
    boardWithBreak() {
      let boardMembersInserted = this.board.slice();
      boardMembersInserted.splice(3, 0, {});
      return boardMembersInserted;
    }
  },
};

/**
 * Meet the board section backup
 */
{/* <section class="meet-the-founders middle">
    <h1>Meet the Board</h1>

    <div class="flex-grid">
      <div :class="[{ element: index !== 3 }, { 'break-row': index === 3 }]" v-for="(member, index) in boardWithBreak" :key="member.name">
        <img v-if="index !== 3" class="circle" :src="`/images/team/${member.img}`" />
        <div v-if="index !== 3" class="overlay overlay-circle">
          <div class="content">
            <h3>{{ member.name }}</h3>
            <div class="social-items">
              <a v-if="member.linkedin" :href="member.linkedin" target="_blank" class="item">
                <i class="fab fa-linkedin"></i>
              </a>
              <a v-if="member.instagram" :href="member.instagram" target="_blank" class="item">
                <i class="fab fa-instagram"></i>
              </a>
              <a v-if="member.website" :href="member.website" target="_blank" class="item">
                <i class="fa fa-user-circle"></i>
              </a>
              <a v-if="member.github" :href="member.github" target="_blank" class="item">
                <i class="fab fa-github"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section> */}
</script>

<style scoped lang="scss">
.about-text {
  .flex-grid {
    display: flex;
    flex-flow: row wrap;
    row-gap: 6rem;
    column-gap: 20px;
  }

  .element {
    display: flex;
    flex-basis: calc(50% - 10px);
    justify-content: center;
    flex-direction: column;

    &.text {
      h2 {
        margin-bottom: 0.5rem;
      }

      p {
        display: flex;
        justify-content: center;
        flex-direction: row;
        word-spacing: 0.05em;
        letter-spacing: 0.01em;
        line-height: 1.75em;
      }
    }

    &.image {
      img {
        margin-left: auto;
        margin-right: auto;
        max-height: 85%;
        max-width: 85%;
      }
    }
  }

  @media screen and (max-width: 600px) {
    .flex-grid {
      flex-direction: column;
    }

    #visImg {
      order: 1;
    }
    #visText {
      order: 2;
    }
    #AchImg {
      order: 3;
    }
    #AchText {
      order: 3;
    }
  }
}

.external-sources {
  text-align: center;

  &-row {
    margin-top: 2rem;

    .col {
      display: flex;

      text-align: start;
      border-radius: 16px;
      padding: 1.5rem;
      width: 500px;
      background-color: var(--color-bg-light);

      img {
        width: 156px;
        height: 156px;
        border-radius: 8px;
        object-fit: cover;
        margin-right: 1rem;
      }

      .source-tag {
        display: inline-block;
        margin-bottom: 1rem;
        padding: 6px 1rem;
        font-size: 16px;
        border: 2px solid var(--color-text-light);
        color: var(--color-text-light);
        border-radius: 100px;
      }

      i {
        margin-right: 10px;
      }

      h3 {
        font-size: 22px;
      }
    }
  }

  @media screen and (max-width: 1300px) {
    .external-sources-row {
      display: block;

      .col {
        margin: 0 auto 1rem;
      }
    }
  }

  @media screen and (max-width: 800px) {
  .external-sources-row .col {
      width: 100%;
      margin: 0 0 1rem;
    }
  }

  @media screen and (max-width: 550px) {
    .external-sources-row img {
      display: none;
    }
  }
}

.partners {
  text-align: center;

  &-row {
    margin-top: 2rem;

    .col {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 200px;
      height: 136px;
      background-color: var(--color-bg-light);
      border-radius: 6px;

      svg, img {
        max-height: 96px;
        max-width: 120px;
        width: auto;
      }
    }
  }

  @media screen and (max-width: 800px) {
    .partners-row {
      display: grid;
      grid-template-columns: 1fr 1fr;

      .col {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.meet-the-founders {
  text-align: center;

  .flex-grid {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    gap: 2rem 5rem;
    margin-top: 2rem;
  }

  .break-row {
    flex-basis: 100%;
    height: 0;
    display: none;
  }

  .element {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
  }

  .overlay {
    // Position the overlay "over" the image
    position: absolute;
    top: 0;
    left: 0;

    // Position the content in the center
    display: flex;
    justify-content: center;
    align-items: center;

    // Set up for the hover animation
    background-color: rgba(0, 0, 0, 0.7);
    opacity: 0;
    transition: 0.4s ease;

    .social-items {
      padding-top: 10px;

      a {
        font-size: 25px;
        color: inherit;
        text-decoration: none;
        transition: 200ms ease;

        & + a {
          margin-left: 24px;
        }
        &:hover {
          color: var(--color-purple-lightest);
        }
      }
    }
  }

  .element:hover .overlay {
    opacity: 1;
  }

  img {
    object-fit: cover;
    aspect-ratio: 1/1;
  }

  .circle {
    width: 240px;
    height: 240px;
    border-radius: 200px;
    background: rgba(245, 245, 245, 0.2);
  }

  .overlay-circle {
    width: 244px;
    height: 244px;
    transform: translate(-2px, -2px);
    border-radius: 200px;
  }

  @media screen and (min-width: 1700px) {
    .break-row {
      display: block;
    }
  }
}
</style>